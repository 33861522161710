export const AMINOACID_NAMES: Record<string, string> = {
  'A': 'Alanine',
  'V': 'Valine',
  'L': 'Leucine',
  'I': 'Isoleucine',
  'B': 'Asparagine or aspartic acid',
  'C': 'Cysteine',
  'D': 'Aspartic acid',
  'E': 'Glutamic acid',
  'F': 'Phenylalanine',
  'G': 'Glycine',
  'H': 'Histidine',
  'K': 'Lysine',
  'M': 'Methionine',
  'N': 'Asparagine',
  'P': 'Proline',
  'Q': 'Glutamine',
  'R': 'Arginine',
  'S': 'Serine',
  'T': 'Threonine',
  'W': 'Tryptophan',
  'X': 'Stop codon',
  'Y': 'Tyrosine',
  'Z': 'Glutamine or Glutamic acid',
  '*': 'Stop codon',
  '-': 'deleted',
}
export const NUCELOTIDE_NAMES: Record<string, string> = {
  'A': 'Adenine',
  'C': 'Cytosine',
  'G': 'Guanine',
  'T': 'Thymine',
  'U': 'Uracil',
  '-': 'Deleted',
}

export const GENE_NAMES: Record<string, string> = {
  E: 'Envelope protein',
  M: 'Membrane protein',
  N: 'Nucleocapsid protein',
  ORF10: 'ORF10',
  ORF14: 'OR14',
  ORF1a: 'ORF1a',
  ORF1b: 'ORF1b',
  ORF3a: 'ORF3a',
  ORF6: 'ORF6',
  ORF7a: 'ORF7a',
  ORF7b: 'ORF7b',
  ORF8: 'ORF8',
  ORF9b: 'ORF9b',
  S: 'Spike protein',
}

/* eslint-disable only-ascii/only-ascii */
export const GREEK_ALPHABET: Record<string, string> = {
  alpha: 'α',
  beta: 'β',
  gamma: 'γ',
  delta: 'δ',
  epsilon: 'ε',
  zeta: 'ζ',
  eta: 'η',
  theta: 'θ',
  iota: 'ι',
  kappa: 'κ',
  lambda: 'λ',
  mu: 'μ',
  nu: 'ν',
  xi: 'ξ',
  omicron: 'ο',
  pi: 'π',
  rho: 'ρ',
  sigma: 'σ',
  tau: 'τ',
  upsilon: 'υ',
  phi: 'φ',
  chi: 'χ',
  psi: 'ψ',
  omega: 'ω',
}
/* eslint-enable only-ascii/only-ascii */
