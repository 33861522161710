export const CLADE_COLORS: Record<string, { bg: string; fg: string }> = {
  '19A': { bg: '#0F2B9D', fg: '#FFFFFF' },
  '19B': { bg: '#302278', fg: '#FFFFFF' },
  '20A': { bg: '#4A1A5A', fg: '#FFFFFF' },
  '20B': { bg: '#691136', fg: '#FFFFFF' },
  '20C': { bg: '#840917', fg: '#FFFFFF' },
  '20D': { bg: '#8E0837', fg: '#FFFFFF' },
  '20E': { bg: '#930876', fg: '#FFFFFF' },
  '20F': { bg: '#9609A7', fg: '#FFFFFF' },
  '20G': { bg: '#9B0AE6', fg: '#FFFFFF' },
  '20H': { bg: '#5E1D9D', fg: '#FFFFFF' },
  '20I': { bg: '#511EA8', fg: '#FFFFFF' },
  '20J': { bg: '#492AB5', fg: '#FFFFFF' },
  '21A': { bg: '#4137C2', fg: '#FFFFFF' },
  '21I': { bg: '#3F47C9', fg: '#FFFFFF' },
  '21J': { bg: '#3E57CE', fg: '#000000' },
  '21B': { bg: '#4067CF', fg: '#000000' },
  '21C': { bg: '#4376CD', fg: '#000000' },
  '21D': { bg: '#4783C8', fg: '#000000' },
  '21E': { bg: '#4C8FC0', fg: '#000000' },
  '21F': { bg: '#519AB7', fg: '#000000' },
  '21G': { bg: '#58A2AC', fg: '#000000' },
  '21H': { bg: '#5FA9A0', fg: '#000000' },
  '21K': { bg: '#68AF93', fg: '#000000' },
  '21L': { bg: '#70B486', fg: '#000000' },
  '22A': { bg: '#7BB77A', fg: '#000000' },
  '22B': { bg: '#85BA6F', fg: '#000000' },
  '22C': { bg: '#90BC65', fg: '#000000' },
  '22D': { bg: '#9CBE5B', fg: '#000000' },
  '22E': { bg: '#A8BE54', fg: '#000000' },
  '22F': { bg: '#B3BD4D', fg: '#000000' },
  '23A': { bg: '#BEBB48', fg: '#000000' },
  '23B': { bg: '#C9B843', fg: '#000000' },
  '23C': { bg: '#D2B340', fg: '#000000' },
  '23D': { bg: '#DAAD3D', fg: '#000000' },
  '23E': { bg: '#E0A33B', fg: '#000000' },
  '23F': { bg: '#E49838', fg: '#000000' },
  '23G': { bg: '#E68B35', fg: '#000000' },
  '23H': { bg: '#E67B32', fg: '#000000' },
  '23I': { bg: '#E5692F', fg: '#000000' },
  '24A': { bg: '#E2562B', fg: '#000000' },
  '24B': { bg: '#DF4227', fg: '#000000' },
  '24C': { bg: '#DC2F24', fg: '#000000' },
}

export const GENE_COLORS: Record<string, string> = {
  E: '#60AA9E',
  M: '#D9A456',
  N: '#D9776C',
  ORF10: '#E67030',
  ORF14: '#8EBC66',
  ORF1a: '#E59637',
  ORF1b: '#AABD52',
  ORF3a: '#C9957B',
  ORF6: '#5097BA',
  ORF7a: '#C4B945',
  ORF7b: '#75B681',
  ORF8: '#55AA81',
  ORF9b: '#D9AD3D',
  S: '#5097BA',
}
export const NUCLEOTIDE_COLORS: Record<string, string> = {
  'A': '#B6EE92',
  'T': '#B4D3FA',
  'G': '#FFD63F',
  'C': '#FBBFAB',
  // 'U': '#8A89FF',
  // 'R': '#FFFE80',
  // 'Y': '#E180FF',
  // 'S': '#FF9B80',
  // 'W': '#80FFF2',
  // 'M': '#CE8834',
  // 'K': '#90B82C',
  // 'D': '#C7FFB9',
  // 'B': '#F8C1C0',
  // 'V': '#FFE3B9',
  // 'H': '#BFD8F9',
  'N': '#AAAAAA',
  'X': '#AAAAAA',
  '-': '#AAAAAA',
}

// Borrowed from http://ugene.net/forum/YaBB.pl?num=1337064665
export const AMINOACID_COLORS: Record<string, string> = {
  'A': '#EAEABA',
  'V': '#EAEA9F',
  'L': '#E1E177',
  'I': '#C9C94D',
  'B': '#AAAAAA',
  'C': '#E3F9B0',
  'D': '#E98F6D',
  'E': '#F7B080',
  'F': '#C7C88D',
  'G': '#C0C0C0',
  'H': '#D6F6FA',
  'K': '#CEC0F3',
  'M': '#C3ED3C',
  'N': '#F29290',
  'P': '#D2D1F8',
  'Q': '#F8C4E3',
  'R': '#A6ACEF',
  'S': '#D8B9D4',
  'T': '#F0D6E3',
  'W': '#86B0CC',
  'X': '#AAAAAA',
  'Y': '#8FC7D1',
  'Z': '#AAAAAA',
  '*': '#AAAAAA',
  '-': '#AAAAAA',
}
